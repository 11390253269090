<template>
    <div>
        <b-modal
              ref="modal"
              title="Upload ShapeFile"
              size="sm"
            >

            <div style ="padding:20px;">
                <b-row> <b-col sm="4"><label>Encoding</label>  </b-col>
                        <b-col sm="8"><b-input size="sm"  type="text" v-model="encoding"/> </b-col>
                
                </b-row>
                <b-row> <b-col sm="4"><label>Tag Name</label>  </b-col>
                        <b-col sm="8"> <b-input size="sm"  type="text" v-model="tag_name"/> </b-col>
                
                </b-row>
                 <b-row> <b-col sm="4"><label>Layer Type</label>  </b-col>
                  <b-col sm="8">    <b-select v-model="selected_layer" :options="layers"></b-select></b-col>
                
                </b-row>                 
               
                <div>
                    <label>Files
                         <input size="sm"  type="file" id="files" ref="files" multiple v-on:change="handleFilesUpload()"/>
                    </label>
                </div>
                <div>
                    <ul>
                        <li v-for="(file, index) in files" :key="index">{{ file.name }} </li>
                    </ul>
                </div>
                <br>
                <div>
                    <b-button v-on:click="addFiles()">Add Files</b-button>
                </div>
                <br>
            </div>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-button @click="submitFiles()">OK</b-button>
                <b-button @click="$refs.modal.hide()">Cancel</b-button>
            </template>

        </b-modal>
    </div>
</template>

<script>

export default {
    data() {
        return {
            files: [],
            encoding: 'euc-kr',
            tag_name: null,
            selected_layer: 0,
            layers: [
                { value: 0, text: 'Pipe' },
                { value: 1, text: 'Valve' },
                { value: 2, text: 'Water Meta' },
                { value: 3, text: 'Pump' },
                { value: 4, text: 'Hydrant' },
                { value: 5, text: 'Junction' },
                { value: 7, text: 'Block' },
                { value: 8, text: 'Water Treatment Plant' },
                { value: 9, text: 'Pumping Station' },
                { value: 10, text: 'Water Reservoir' },
                { value: 11, text: 'Chamber' },
                { value: 12, text: 'Gauge' },
                { value: 13, text: 'Other' },
            ]
        }
    },


    methods: {
        show() {
            this.files = []
            this.$refs.modal.show();
        },
        addFiles() {
            this.$refs.files.click();
        },
        submitFiles(){
            let formData = new FormData();
            let a = 0
            let b = 0
            for( var i = 0; i < this.files.length; i++ ){
                let file = this.files[i];
                let fileName = file.name;
                let fileExtension = fileName.split('.').pop();

                if( fileExtension.toLowerCase() ==="dbf" ){
                    a=a+1;
                }else if( fileExtension.toLowerCase() ==="shp")  {
                    b=b+1
                }

                formData.append('files[' + i + ']', file);
            }
            if(a !== 1 && b !== 1  ){
                alert("dbf.,shp 파일을 두개를 업로드 하셔야 합니다.")
                return
            }else if(this.files.length>2){
                alert("dbf.,shp 파일을 두개를 업로드 하셔야 합니다.")
                return
            }
          //  this.$http.post(this.URL+'api/upload_shape?layer='+this.selected_layer+'&encoding='+this.encoding+'&tag_name='+this.tag_name, formData, {
              this.$http.post(this.URL+'api/upload_shape?m_idx='+sessionStorage.m_idx+'&c_id='+sessionStorage.select_customer_id+'&layer='+this.selected_layer+'&encoding='+this.encoding+'&tag_name='+this.tag_name, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                console.log('SUCCESS!!');
                this.$refs.modal.hide();
            })
            .catch(() => {
                console.log('FAILURE!!');
                this.$refs.modal.hide();
            });
        },
        handleFilesUpload(){
            let uploadedFiles = this.$refs.files.files;
            for( var i = 0; i < uploadedFiles.length; i++ ){
                this.files.push( uploadedFiles[i] );
            }
        },
        removeFile( key ){
            this.files.splice( key, 1 );
        }
    }
}
</script>

<style  lang="scss">
  input[type="file"]{
    position: absolute;
    top: -500px;
  }
  div.file-listing{
    width: 200px;
  }
  span.remove-file{
    color: red;
    cursor: pointer;
    float: right;
  }
</style>
